<template>
  <div>
    <CookieConsentPopup />
    <Suspense>
      <NuxtPage />
      <template #fallback>
        <FullscreenLoading />
      </template>
    </Suspense>
    <UNotifications />
  </div>
</template>
