import { default as confirmnL92ULPLJcMeta } from "/home/yuyue/Documents/Projects/embbay/packages/web/pages/confirm.vue?macro=true";
import { default as indexCziSB5jT2TMeta } from "/home/yuyue/Documents/Projects/embbay/packages/web/pages/index.vue?macro=true";
import { default as _91_91method_93_939W2oSKymBrMeta } from "/home/yuyue/Documents/Projects/embbay/packages/web/pages/login/[[method]].vue?macro=true";
import { default as playWOYKp6CBGMMeta } from "/home/yuyue/Documents/Projects/embbay/packages/web/pages/play.vue?macro=true";
import { default as _91_46_46_46slug_93yhO7dvzFwiMeta } from "/home/yuyue/Documents/Projects/embbay/packages/web/pages/public/docs/[...slug].vue?macro=true";
import { default as cookie_45policyEmtx4kiw2EMeta } from "/home/yuyue/Documents/Projects/embbay/packages/web/pages/public/docs/cookie-policy.vue?macro=true";
import { default as indexMxjREVaaUYMeta } from "/home/yuyue/Documents/Projects/embbay/packages/web/pages/public/docs/index.vue?macro=true";
import { default as _91_46_46_46id_93vG6uUNbVRyMeta } from "/home/yuyue/Documents/Projects/embbay/packages/web/pages/public/embfile/[...id].vue?macro=true";
import { default as registerHMLCj8XXNTMeta } from "/home/yuyue/Documents/Projects/embbay/packages/web/pages/register.vue?macro=true";
import { default as moreR1Sb6E5vbRMeta } from "/home/yuyue/Documents/Projects/embbay/packages/web/pages/workshop/more.vue?macro=true";
import { default as previewMORyNZze5KMeta } from "/home/yuyue/Documents/Projects/embbay/packages/web/pages/workshop/preview.vue?macro=true";
import { default as workshopdOpf6DrW8TMeta } from "/home/yuyue/Documents/Projects/embbay/packages/web/pages/workshop.vue?macro=true";
export default [
  {
    name: confirmnL92ULPLJcMeta?.name ?? "confirm",
    path: confirmnL92ULPLJcMeta?.path ?? "/confirm",
    meta: confirmnL92ULPLJcMeta || {},
    alias: confirmnL92ULPLJcMeta?.alias || [],
    redirect: confirmnL92ULPLJcMeta?.redirect || undefined,
    component: () => import("/home/yuyue/Documents/Projects/embbay/packages/web/pages/confirm.vue").then(m => m.default || m)
  },
  {
    name: indexCziSB5jT2TMeta?.name ?? "index",
    path: indexCziSB5jT2TMeta?.path ?? "/",
    meta: indexCziSB5jT2TMeta || {},
    alias: indexCziSB5jT2TMeta?.alias || [],
    redirect: indexCziSB5jT2TMeta?.redirect || undefined,
    component: () => import("/home/yuyue/Documents/Projects/embbay/packages/web/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91_91method_93_939W2oSKymBrMeta?.name ?? "login-method",
    path: _91_91method_93_939W2oSKymBrMeta?.path ?? "/login/:method?",
    meta: _91_91method_93_939W2oSKymBrMeta || {},
    alias: _91_91method_93_939W2oSKymBrMeta?.alias || [],
    redirect: _91_91method_93_939W2oSKymBrMeta?.redirect || undefined,
    component: () => import("/home/yuyue/Documents/Projects/embbay/packages/web/pages/login/[[method]].vue").then(m => m.default || m)
  },
  {
    name: playWOYKp6CBGMMeta?.name ?? "play",
    path: playWOYKp6CBGMMeta?.path ?? "/play",
    meta: playWOYKp6CBGMMeta || {},
    alias: playWOYKp6CBGMMeta?.alias || [],
    redirect: playWOYKp6CBGMMeta?.redirect || undefined,
    component: () => import("/home/yuyue/Documents/Projects/embbay/packages/web/pages/play.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93yhO7dvzFwiMeta?.name ?? "public-docs-slug",
    path: _91_46_46_46slug_93yhO7dvzFwiMeta?.path ?? "/public/docs/:slug(.*)*",
    meta: _91_46_46_46slug_93yhO7dvzFwiMeta || {},
    alias: _91_46_46_46slug_93yhO7dvzFwiMeta?.alias || [],
    redirect: _91_46_46_46slug_93yhO7dvzFwiMeta?.redirect || undefined,
    component: () => import("/home/yuyue/Documents/Projects/embbay/packages/web/pages/public/docs/[...slug].vue").then(m => m.default || m)
  },
  {
    name: cookie_45policyEmtx4kiw2EMeta?.name ?? "public-docs-cookie-policy",
    path: cookie_45policyEmtx4kiw2EMeta?.path ?? "/public/docs/cookie-policy",
    meta: cookie_45policyEmtx4kiw2EMeta || {},
    alias: cookie_45policyEmtx4kiw2EMeta?.alias || [],
    redirect: cookie_45policyEmtx4kiw2EMeta?.redirect || undefined,
    component: () => import("/home/yuyue/Documents/Projects/embbay/packages/web/pages/public/docs/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: indexMxjREVaaUYMeta?.name ?? "public-docs",
    path: indexMxjREVaaUYMeta?.path ?? "/public/docs",
    meta: indexMxjREVaaUYMeta || {},
    alias: indexMxjREVaaUYMeta?.alias || [],
    redirect: indexMxjREVaaUYMeta?.redirect || undefined,
    component: () => import("/home/yuyue/Documents/Projects/embbay/packages/web/pages/public/docs/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46id_93vG6uUNbVRyMeta?.name ?? "public-embfile-id",
    path: _91_46_46_46id_93vG6uUNbVRyMeta?.path ?? "/public/embfile/:id(.*)*",
    meta: _91_46_46_46id_93vG6uUNbVRyMeta || {},
    alias: _91_46_46_46id_93vG6uUNbVRyMeta?.alias || [],
    redirect: _91_46_46_46id_93vG6uUNbVRyMeta?.redirect || undefined,
    component: () => import("/home/yuyue/Documents/Projects/embbay/packages/web/pages/public/embfile/[...id].vue").then(m => m.default || m)
  },
  {
    name: registerHMLCj8XXNTMeta?.name ?? "register",
    path: registerHMLCj8XXNTMeta?.path ?? "/register",
    meta: registerHMLCj8XXNTMeta || {},
    alias: registerHMLCj8XXNTMeta?.alias || [],
    redirect: registerHMLCj8XXNTMeta?.redirect || undefined,
    component: () => import("/home/yuyue/Documents/Projects/embbay/packages/web/pages/register.vue").then(m => m.default || m)
  },
  {
    name: workshopdOpf6DrW8TMeta?.name ?? "workshop",
    path: workshopdOpf6DrW8TMeta?.path ?? "/workshop",
    children: [
  {
    name: moreR1Sb6E5vbRMeta?.name ?? "workshop-more",
    path: moreR1Sb6E5vbRMeta?.path ?? "more",
    meta: moreR1Sb6E5vbRMeta || {},
    alias: moreR1Sb6E5vbRMeta?.alias || [],
    redirect: moreR1Sb6E5vbRMeta?.redirect || undefined,
    component: () => import("/home/yuyue/Documents/Projects/embbay/packages/web/pages/workshop/more.vue").then(m => m.default || m)
  },
  {
    name: previewMORyNZze5KMeta?.name ?? "workshop-preview",
    path: previewMORyNZze5KMeta?.path ?? "preview",
    meta: previewMORyNZze5KMeta || {},
    alias: previewMORyNZze5KMeta?.alias || [],
    redirect: previewMORyNZze5KMeta?.redirect || undefined,
    component: () => import("/home/yuyue/Documents/Projects/embbay/packages/web/pages/workshop/preview.vue").then(m => m.default || m)
  }
],
    meta: workshopdOpf6DrW8TMeta || {},
    alias: workshopdOpf6DrW8TMeta?.alias || [],
    redirect: workshopdOpf6DrW8TMeta?.redirect || undefined,
    component: () => import("/home/yuyue/Documents/Projects/embbay/packages/web/pages/workshop.vue").then(m => m.default || m)
  }
]