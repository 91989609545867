import revive_payload_client_igbvrQFLif from "/home/yuyue/Documents/Projects/embbay/node_modules/.pnpm/nuxt@3.8.0_@types+node@18.18.7_typescript@5.2.2_vite@4.5.0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_Sm6eXyWp5f from "/home/yuyue/Documents/Projects/embbay/node_modules/.pnpm/nuxt@3.8.0_@types+node@18.18.7_typescript@5.2.2_vite@4.5.0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_AqsL89mP4K from "/home/yuyue/Documents/Projects/embbay/node_modules/.pnpm/nuxt@3.8.0_@types+node@18.18.7_typescript@5.2.2_vite@4.5.0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import supabase_client_S1vrQn3pZ1 from "/home/yuyue/Documents/Projects/embbay/node_modules/.pnpm/@nuxtjs+supabase@1.1.3/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.mjs";
import payload_client_SX48S9qR8q from "/home/yuyue/Documents/Projects/embbay/node_modules/.pnpm/nuxt@3.8.0_@types+node@18.18.7_typescript@5.2.2_vite@4.5.0/node_modules/nuxt/dist/app/plugins/payload.client.js";
import components_plugin_KR1HBZs4kY from "/home/yuyue/Documents/Projects/embbay/packages/web/.nuxt/components.plugin.mjs";
import prefetch_client_beFVTPDrV7 from "/home/yuyue/Documents/Projects/embbay/node_modules/.pnpm/nuxt@3.8.0_@types+node@18.18.7_typescript@5.2.2_vite@4.5.0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import auth_redirect_RD6AS0i2Ox from "/home/yuyue/Documents/Projects/embbay/node_modules/.pnpm/@nuxtjs+supabase@1.1.3/node_modules/@nuxtjs/supabase/dist/runtime/plugins/auth-redirect.mjs";
import colors_7mpEToeXE1 from "/home/yuyue/Documents/Projects/embbay/node_modules/.pnpm/@nuxt+ui@2.9.0_vue@3.3.7_webpack@5.89.0/node_modules/@nuxt/ui/dist/runtime/plugins/colors.mjs";
import plugin_client_rMDYCVR6s7 from "/home/yuyue/Documents/Projects/embbay/node_modules/.pnpm/@nuxtjs+color-mode@3.3.0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import chunk_reload_client_j1ZaPEzFs2 from "/home/yuyue/Documents/Projects/embbay/node_modules/.pnpm/nuxt@3.8.0_@types+node@18.18.7_typescript@5.2.2_vite@4.5.0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import check_outdated_build_client_gPgnC5RuBM from "/home/yuyue/Documents/Projects/embbay/node_modules/.pnpm/nuxt@3.8.0_@types+node@18.18.7_typescript@5.2.2_vite@4.5.0/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
export default [
  revive_payload_client_igbvrQFLif,
  unhead_Sm6eXyWp5f,
  router_AqsL89mP4K,
  supabase_client_S1vrQn3pZ1,
  payload_client_SX48S9qR8q,
  components_plugin_KR1HBZs4kY,
  prefetch_client_beFVTPDrV7,
  auth_redirect_RD6AS0i2Ox,
  colors_7mpEToeXE1,
  plugin_client_rMDYCVR6s7,
  chunk_reload_client_j1ZaPEzFs2,
  check_outdated_build_client_gPgnC5RuBM
]